<template>
  <div class="app-container" />
</template>

<script lang="ts">
import { useStore } from '@/store'
import { reactive, defineComponent, toRefs, onMounted } from 'vue'
 interface Profile {
  name: string
  email: string
  avatar: string
  roles: string
}
export default defineComponent({
  components: {
  },
  setup() {
    const defaultProfile: Profile = {
      name: 'Yours Extra.',
      email: 'Loading...',
      avatar: 'Loading...',
      roles: 'Loading...'
    }
    const store = useStore()
    const dataMap = reactive({

      user: defaultProfile,
      activeTab: 'activity',

      name: () => {
        return store.state.user.name
      },

      email() {
        return store.state.user.email
      },
      avatar() {
        return store.state.user.avatar
      },
      roles() {
        return store.state.user.roles
      },
      getUser: () => {
        dataMap.user = {
          name: dataMap.name(),
          email: dataMap.email(),
          avatar: dataMap.avatar(),
          roles: dataMap.roles().join(' | ')
        }
      }

    })

    onMounted(() => {
      dataMap.getUser()
    })

    return { ...toRefs(dataMap) }
  }
})
</script>
